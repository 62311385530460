export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    {
        title: 'Projects',
        url: '/projects',
        cName: 'nav-links'
    },
    {
        title: 'Resume',
        url: '/resume',
        cName: 'nav-links'
    },
    {
        title: 'Contact Me',
        url: '/contact-me',
        cName: 'nav-links'
    }
    // ,
    // {
    //     title: 'Sign up',
    //     url: '#',
    //     cName: 'nav-links-mobile'
    // }
]